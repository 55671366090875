import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Events are interactions that the webpage or the web application detects. Users generate events when they do one of the following:`}<ul><li>{`Clicks on the web page or web application.`}</li><li>{`Page scroll.`}</li><li>{`Form submissions.`}</li></ul>{`Automated workflows generate events when they do one of the following:`}<ul><li>{`Scheduled updates.`}</li><li>{`Data changes.`}</li></ul>{`CoreMedia Experience Platform generates events when it does one of the following:`}<ul><li>{`Page loads.`}</li><li>{`API responses`}</li><li>{`Error generation.`}</li></ul>{`CoreMedia Experience Platfor use events for web tracking, analytics, and dynamic user experiences.`}<br /><br />{`Events related panels contain the list of all events that users, workflows, and CoreMedia Experience platform generate and contain event associated metrics.`}</p>
    <p>{`The following tables list the metrics of Events aggregation panel, sorted by alphabetic order. Use `}<em parentName="p">{`CRTL + F`}</em>{` in Windows or `}<em parentName="p">{`Command + F`}</em>{` in MacOS to search inside the page.`}</p>
    <h2>{`All`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Metric`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Events`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul><li><b>{`# - Count`}</b></li><li><b>{`% - Percentage`}</b></li></ul></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      